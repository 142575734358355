import { create } from "zustand";

export interface IMintStore {
  openModal: boolean;
  setOpenModal: (openModal: boolean) => void;
  chestSize: number;
  setChestSize: (mint: number) => void;
  mint: boolean;
  setMint: (mint: boolean) => void;
  hash: string;
  setHash: (mint: string) => void;
  error: boolean;
  setError: (error: boolean) => void;
  success: boolean;
  setSuccess: (success: boolean) => void;
}

export const useMintStore = create<IMintStore>((set, get) => ({
  openModal: false,
  setOpenModal: (openModal) => set({ openModal }),
  chestSize: 0,
  setChestSize: (chestSize) => set({ chestSize }),
  mint: false,
  setMint: (mint) => set({ mint }),
  hash: "",
  setHash: (hash) => set({ hash }),
  error: false,
  setError: (error) => set({ error }),
  success: false,
  setSuccess: (success) => set({ success }),
}));
