"use client";
import { landsAddress, landSaleAddress, usdtTestAddress } from "@/constants";
import { useAuth } from "@/context/AuthContext";
import {
  LandsAbi__factory,
  LandSaleAbi__factory,
  UsdtTestAbi__factory,
} from "@/contracts";
import { INfts } from "@/interfaces/INfts";
import { Api } from "@/provider";
import { useMintStore } from "@/stores/mintStore";
import { useNftStore } from "@/stores/nftsStore";
import axios from "axios";
import { useAccount } from "wagmi";

type TOpenChest = {
  uriToken: string;
  address: string;
};

export default function useSales() {
  const { wallet, provider } = useAuth();
  const { setError, setHash, setChestSize, setMint, setOpenModal, setSuccess } =
    useMintStore();
  const { setNfts } = useNftStore();
  const { address } = useAccount();

  const signer = wallet ? wallet : provider;

  const landSaleContract = LandSaleAbi__factory.connect(
    landSaleAddress,
    signer
  );
  const landsContract = LandsAbi__factory.connect(landsAddress, provider);
  const usdtContract = UsdtTestAbi__factory.connect(usdtTestAddress, signer);

  async function getSupply() {
    const supply = await landSaleContract.getTotalSupply();
    return [Number(supply[0]), Number(supply[1]), Number(supply[2])];
  }

  async function refreshOpensea(tokenId: string) {
    const url = `https://api.opensea.io/api/v2/chain/matic/contract/${landsAddress}/nfts/${tokenId}/refresh`;
    const headers = { "x-api-key": "40d7d0a9cb9a4f5ba0974434fec7185a" };
    return await axios.post(url, {}, { headers });
  }

  async function tokenURI(tokenId: string) {
    const uri = await landsContract.tokenURI(tokenId);
    return uri;
  }

  async function openChest(payload: TOpenChest) {
    const { data } = await Api.post("/lands/open-chest", payload);
    return data;
  }

  async function getNfts(address: string) {
    const url = `https://api.opensea.io/api/v2/chain/matic/account/${address}/nfts`;

    const { data } = await axios.get(url, {
      headers: { "x-api-key": "40d7d0a9cb9a4f5ba0974434fec7185a" },
    });
    return data.nfts as INfts[];
  }

  async function getNftByUrl(metadata_url: string) {
    const { data } = await axios.get(metadata_url);
    return data;
  }

  async function checkRole() {
    try {
      if (!address) throw Error("No address");
      const isOg = await landSaleContract.isOg(address!);
      if (isOg) return "OG";
      const isWhitelist = await landSaleContract.isWhitelist(address!);
      if (isWhitelist) return "WHITELIST";
      return "PUBLIC";
    } catch (error) {
      console.error(error);
    }
  }

  async function getPrice(role: string) {
    if (role === "OG") return await landSaleContract.getOgPrice();
    return await landSaleContract.getNormalPrice();
  }

  async function onMint(landType: number, roleTime: string) {
    try {
      if (!address) throw Error("No address");
      const chestSize = Number(landType + 1);
      setError(false);
      setSuccess(false);
      setMint(false);
      setOpenModal(true);
      setChestSize(chestSize);
      const landSaleContract = LandSaleAbi__factory.connect(
        landSaleAddress,
        signer
      );
      const amountRes = await getPrice(roleTime);
      const amount = Number(amountRes[landType]).toString();
      console.log(amount);
      const approve = await usdtContract.approve(landSaleAddress, amount);
      await approve.wait(1);
      setMint(true);
      const mint = await landSaleContract.buy(chestSize, {
        gasLimit: 500000,
      });
      const tx = await mint.wait(1);
      if (tx) {
        setSuccess(true);
        setHash(tx.hash);
        setMint(true);
      }
      const nfts = await getNfts(address);
      setNfts(nfts);
      return tx;
    } catch (error: any) {
      setError(true);
      console.error("Transaction failed:", error);
      if (error) {
        try {
          const parsedError = landSaleContract.interface.parseError(error);
          console.error("Custom Error:", parsedError);
        } catch (err) {
          console.error("Failed to decode custom error:", err);
        }
      }
    }
  }

  async function getTime() {
    try {
      const [saleTimetx, timetx] = await Promise.all([
        landSaleContract.getSaleTime(),
        landSaleContract.getTime(),
      ]);
      const [ogTime, whiteListTime] = saleTimetx.map(Number);
      let currentTime = Number(timetx);
      const now = new Date();
      const startOgTime = new Date(2024, 7, 29);
      startOgTime.setHours(11, 0, 0, 0);
      const before = ogTime === 21600 && whiteListTime === 108000;
      const tomorrowTime = (Number(startOgTime) - Number(now)) / 1000;
      if (before) currentTime = tomorrowTime <= 0 ? 0 : tomorrowTime;
      let timestamp: number;
      let roleTime: string;
      if (before) {
        roleTime = "BEFORE";
        timestamp = currentTime;
      } else if (currentTime < ogTime) {
        roleTime = "OG";
        timestamp = ogTime - currentTime;
      } else if (currentTime < whiteListTime) {
        roleTime = "WHITELIST";
        timestamp = whiteListTime - currentTime;
      } else {
        roleTime = "PUBLIC";
        timestamp = 0;
      }

      const checkOgtime = before ? timestamp : ogTime - currentTime;
      const ogTimeRemain = checkOgtime <= 0 ? 0 : checkOgtime;
      const checkwhitelistTime = before ? checkOgtime + ogTime : whiteListTime;
      const whitelistTime = before
        ? checkwhitelistTime
        : checkwhitelistTime <= 0
        ? 0
        : ogTimeRemain;
      const publicTime = before
        ? ogTimeRemain + whiteListTime
        : ogTimeRemain + checkwhitelistTime - currentTime;

      return { timestamp, ogTimeRemain, whitelistTime, publicTime, roleTime };
    } catch (error) {
      console.error(error);
    }
  }

  return {
    onMint,
    getTime,
    getNftByUrl,
    getSupply,
    getPrice,
    checkRole,
    openChest,
    getNfts,
    refreshOpensea,
    tokenURI,
  };
}
