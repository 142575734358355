import { INfts } from "@/interfaces/INfts";
import { create } from "zustand";

export interface INftStore {
  nfts: INfts[];
  getNfts: ()=> INfts[];
  setNfts: (nfts: INfts[]) => void;
}

export const useNftStore = create<INftStore>((set, get) => ({
  nfts: [],
  getNfts: () => get().nfts.filter((item)=>item.contract === '0x2e831226b9d0dc9591e5ef3564b9c4529b2fa2e4'),
  setNfts: (nfts) => set({ nfts }),
}));
